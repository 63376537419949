import './selectedProjectContent.scss'
import ArticleCarousel from './projectPicturesCarousel'

const SelectedProjectContent = ({ selectedProject, stopVideo, openModal }) => {
    const skills = selectedProject.skills

    return (
        <div className="project__selected-content">
            <h3 className="project__subtitle">{selectedProject.title}</h3>
            <div className="project__carousel">
                <ArticleCarousel
                    medias={selectedProject.medias}
                    stopVideo={stopVideo}
                />
                <button
                    className="open-modal-button"
                    onClick={() => openModal(selectedProject)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path d="M32 32C14.3 32 0 46.3 0 64l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7 14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0 0-64zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0 0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-96c0-17.7-14.3-32-32-32l-96 0zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 64-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32l0-96z" />
                    </svg>
                </button>
            </div>

            <div className="project__content">
                <div className="project__content__description-wrapper">
                    {selectedProject.description.map((paragraphe, index) => (
                        <p
                            className="project__content--description"
                            key={index}
                        >
                            {paragraphe}
                        </p>
                    ))}
                </div>
                <div className="project__content__skills">
                    <div className="project__content__skills__title">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="28"
                            viewBox="0 0 32 28"
                            fill="none"
                        >
                            <path
                                d="M14.1562 3.80605C15.05 6.4873 14.1375 9.19355 12.1187 9.85605C10.1 10.5186 7.73747 8.88105 6.84372 6.1998C5.94997 3.51855 6.86247 0.812301 8.88122 0.149801C10.9 -0.512699 13.2625 1.1248 14.1562 3.80605ZM6.27497 10.4123C7.45622 12.4373 7.16872 14.7936 5.63747 15.6686C4.10622 16.5436 1.90622 15.6123 0.731219 13.5873C-0.443781 11.5623 -0.168781 9.20605 1.36247 8.33105C2.89372 7.45605 5.09372 8.3873 6.26872 10.4123H6.27497ZM4.32497 23.0748C7.59997 14.2436 13.4187 11.9998 16 11.9998C18.5812 11.9998 24.4 14.2436 27.675 23.0748C27.9 23.6811 28 24.3311 28 24.9811V25.081C28 26.6935 26.6937 27.9998 25.0812 27.9998C24.3625 27.9998 23.65 27.9123 22.9562 27.7373L17.4562 26.3623C16.5 26.1248 15.5 26.1248 14.5437 26.3623L9.04372 27.7373C8.34997 27.9123 7.63747 27.9998 6.91872 27.9998C5.30622 27.9998 3.99997 26.6935 3.99997 25.081V24.9811C3.99997 24.3311 4.09997 23.6811 4.32497 23.0748ZM26.3625 15.6686C24.8312 14.7936 24.5437 12.4373 25.725 10.4123C26.9062 8.3873 29.1 7.45605 30.6312 8.33105C32.1625 9.20605 32.45 11.5623 31.2687 13.5873C30.0875 15.6123 27.8937 16.5436 26.3625 15.6686ZM19.3812 9.85605C17.3625 9.19355 16.45 6.4873 17.3437 3.80605C18.2375 1.1248 20.6 -0.512699 22.6187 0.149801C24.6375 0.812301 25.55 3.51855 24.6562 6.1998C23.7625 8.88105 21.4 10.5186 19.3812 9.85605Z"
                                fill="black"
                            />
                        </svg>
                        <h4>Compétences & outils</h4>
                    </div>
                    <ul className="project__content__skill-list">
                        {skills.map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SelectedProjectContent

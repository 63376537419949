import CatTail from '../atoms/catTail'

const ProjectHeader = ({ project, selectedProject, onClick, colorsClass }) => {
    return (
        <div className="project__header-container">
            <div
                className={`project__header ${colorsClass} ${selectedProject?.id === project.id ? 'project__selected' : ''}`}
                onClick={onClick}
            >
                <h3 className="project__title">{project.title}</h3>
                <p>{project.brand}</p>
                <img
                    className="project__picto"
                    src={project.picto.url}
                    alt={project.picto.pictoAltText}
                />
            </div>
            <div className="cat-tail-container">
                <CatTail
                    color={colorsClass}
                    selected={
                        selectedProject?.id === project.id
                            ? 'selected-tail'
                            : ''
                    }
                />
            </div>
        </div>
    )
}

export default ProjectHeader

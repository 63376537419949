import { NavLink } from 'react-router-dom'
import PageTitle from '../components/molecules/pageTitle'
import './error.scss'
import errorCat from '../assets/images/errorCat.png'

const ErrorPage = () => {
    return (
        <main className="errorPage page-anim">
            <div className="error__text">
                <PageTitle title="404" subtitle="Cat not found" />
                <NavLink className="linkBtn" to="/">
                    HOME
                </NavLink>
            </div>
            <img className="big-mandouille" src={errorCat} alt="Chat noir" />
        </main>
    )
}

export default ErrorPage

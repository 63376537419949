import { useMediaQuery } from 'react-responsive'

const BigCat = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 530px)' })
    if (isMobile) {
        return (
            <svg
                className="cv__languages__cat--big cv__languages__cat--mobile"
                xmlns="http://www.w3.org/2000/svg"
                width="425"
                height="495"
                viewBox="0 0 425 495"
                fill="none"
            >
                <path
                    d="M0 184.259C0 104.98 64.4174 40.7124 143.88 40.7124H281.12C360.583 40.7124 425 104.98 425 184.259V725.32C425 804.599 360.583 868.867 281.12 868.867H143.88C64.4174 868.867 0 804.599 0 725.32V184.259Z"
                    fill="#FFFEF7"
                />
                <path
                    d="M51.6087 4.94351C53.3425 -1.64784 62.7212 -1.64784 64.455 4.94351L115.09 153.868C116.195 158.068 113.019 162.175 108.667 162.175H10.8643C6.5121 162.175 3.33645 158.068 4.4412 153.868L51.6087 4.94351Z"
                    fill="#FFFEF7"
                />
                <path
                    d="M357.077 4.94351C358.811 -1.64784 368.19 -1.64784 369.924 4.94351L420.559 153.868C421.664 158.068 418.488 162.175 414.136 162.175H316.333C311.981 162.175 308.805 158.068 309.91 153.868L357.077 4.94351Z"
                    fill="#FFFEF7"
                />
            </svg>
        )
    }
    return (
        <svg
            className="cv__languages__cat--big"
            xmlns="http://www.w3.org/2000/svg"
            width="1086"
            height="367"
            viewBox="0 0 1086 367"
            fill="none"
        >
            <path
                d="M0 469.943C0 267.747 164.605 103.835 367.656 103.835H718.344C921.395 103.835 1086 267.747 1086 469.943V1849.89C1086 2052.09 921.395 2216 718.344 2216H367.656C164.605 2216 0 2052.09 0 1849.89V469.943Z"
                fill="#FFFEF7"
            />
            <path
                d="M131.875 12.6082C136.306 -4.20272 160.271 -4.20272 164.701 12.6082L294.089 392.432C296.912 403.144 288.797 413.619 277.676 413.619H27.7615C16.6403 413.619 8.52561 403.144 11.3486 392.432L131.875 12.6082Z"
                fill="#FFFEF7"
            />
            <path
                d="M912.438 12.6082C916.868 -4.20272 940.834 -4.20272 945.264 12.6082L1074.65 392.432C1077.47 403.144 1069.36 413.619 1058.24 413.619H808.324C797.203 413.619 789.088 403.144 791.911 392.432L912.438 12.6082Z"
                fill="#FFFEF7"
            />
        </svg>
    )
}

export default BigCat

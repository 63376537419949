import { NavLink } from 'react-router-dom'
import Logo from '../atoms/logo'
import NavbarDesktop from '../molecules/navbarDesktop'
import './Header.scss'

const Header = () => {
    return (
        <header>
            <NavLink to="/">
                <Logo />

                <div className="name-and-title">
                    <span className="name">Mélanie Chabrol</span>
                    <span className="job">UX UI Designer</span>
                </div>
            </NavLink>

            <NavbarDesktop />
        </header>
    )
}

export default Header

import { useMediaQuery } from 'react-responsive'
import './skillBar.scss'

const SkillBar = ({ title, color, children, collapsed, onClick, reverse }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 530px)' })
    // verify max-width value in the skillbar.scss they should be the same
    const renderSvg = () => {
        switch (true) {
            case isMobile && reverse:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="281"
                        height="92"
                        viewBox="0 0 281 92"
                        fill="none"
                    >
                        <path
                            d="M-36.5 61.7371C-24.4237 47.4038 15.9185 21.5371 80.6775 32.7371C161.626 46.7371 182.571 65.2371 250.5 41.2371"
                            stroke={color}
                            stroke-width="60"
                            stroke-linecap="round"
                        />
                    </svg>
                )

            case !isMobile && reverse:
                return (
                    <svg
                        onClick={onClick}
                        xmlns="http://www.w3.org/2000/svg"
                        width="550"
                        height="92"
                        viewBox="0 0 550 92"
                        fill="none"
                        className="skill-bar__title-bg"
                    >
                        <path
                            d="M-40 62C-16.4366 47.5479 62.2801 21.467 188.639 32.7598C346.588 46.8757 387.456 65.529 520 41.3302"
                            stroke={color}
                            strokeWidth="60"
                            strokeLinecap="round"
                        />
                    </svg>
                )
            case isMobile:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="270"
                        height="90"
                        viewBox="0 0 270 90"
                        fill="none"
                    >
                        <path
                            d="M282.5 60.7371C271.833 46.4038 236.2 20.5371 179 31.7371C107.5 45.7371 89 64.2371 29 40.2371"
                            stroke={color}
                            stroke-width="58"
                            stroke-linecap="round"
                        />
                    </svg>
                )

            default:
                return (
                    <svg
                        onClick={onClick}
                        xmlns="http://www.w3.org/2000/svg"
                        width="534"
                        height="84"
                        viewBox="0 0 534 84"
                        fill="none"
                        className="skill-bar__title-bg"
                    >
                        <path
                            d="M547 58C525.078 43.5479 451.843 17.467 334.284 28.7598C187.335 42.8757 149.314 61.529 26 37.3302"
                            stroke={color}
                            strokeWidth="51"
                            strokeLinecap="round"
                        />
                    </svg>
                )
        }
    }

    return (
        <div className={`skill-bar ${reverse === true ? 'reverse' : ''}`}>
            {renderSvg()}
            <div className="skill-bar__title" onClick={onClick}>
                <h3>{title}</h3>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="17"
                    viewBox="0 0 26 17"
                    fill="none"
                    className={`${!collapsed ? 'skill-bar__title__arrow skill-bar__title__arrow--open' : 'skill-bar__title__arrow'}`}
                >
                    <path
                        d="M3 2.99999L12.2318 14.0781C12.6316 14.5579 13.3684 14.5579 13.7682 14.0781L23 2.99999"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
            <div
                className={`skill-bar__content ${!collapsed ? 'sb__c--open' : 'sb__c--closed'}`}
            >
                {children}
            </div>
        </div>
    )
}

export default SkillBar

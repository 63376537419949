import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Footer from './components/templates/Footer'
import Header from './components/templates/Header'
import CVPage from './pages/CV'
import Home from './pages/home'
import ErrorPage from './pages/error'
import PortfolioPage from './pages/Portfolio'
import './index.scss'
import { DataProvider } from './assets/datas'
import { CarouselProvider } from './assets/carouselIndexHandler'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <DataProvider>
            <BrowserRouter>
                <Header />
                <div className="main-container">
                    <CarouselProvider>
                        <Routes>
                            <Route exact path="/" element={<Home />} />

                            <Route
                                path="/portfolio"
                                element={<PortfolioPage />}
                            />

                            <Route path="/curriculum" element={<CVPage />} />
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                    </CarouselProvider>
                </div>
                <Footer />
            </BrowserRouter>
        </DataProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useState } from 'react'
import SkillBar from '../molecules/skillBar'
import './skillSection.scss'

const SkillSection = () => {
    const [activeSkillBar, setActiveSkillBar] = useState('Soft Skills')

    const handleSkillBarClick = (title) => {
        setActiveSkillBar((prevTitle) => (prevTitle === title ? null : title))
    }

    return (
        <section className="skill-section">
            <SkillBar
                title="Soft Skills"
                color="#E48471"
                collapsed={activeSkillBar !== 'Soft Skills'}
                onClick={() => handleSkillBarClick('Soft Skills')}
            >
                <ul className="ulist">
                    <li>Ecoute active et curiosité</li>
                    <li>Patience et adaptabilité</li>
                    <li>Rigueur et organisation</li>
                    <li>Respect et confiance</li>
                </ul>
            </SkillBar>
            <SkillBar
                title="Hard Skills"
                color="#CBB4E1"
                collapsed={activeSkillBar !== 'Hard Skills'}
                onClick={() => handleSkillBarClick('Hard Skills')}
            >
                <ul className="ulist">
                    <li>Suite Adobe</li>
                    <li>Sketch, XD & Figma</li>
                    <li>HTML / CSS</li>
                    <li>Retouche photo</li>
                    <li>Montage vidéo</li>
                    <li>Spark AR (filtres instagram)</li>
                    <li>Open AI (Prompting)</li>
                </ul>
            </SkillBar>
            <SkillBar
                title="Intérêts"
                color="#EA8D00"
                collapsed={activeSkillBar !== 'Intérêts'}
                onClick={() => handleSkillBarClick('Intérêts')}
            >
                <ul className="ulist">
                    <li>Illustration</li>
                    <li>Crochet</li>
                    <li>Musique, chant et basse</li>
                    <li>Photographie</li>
                    <li>Balades à vélo</li>
                    <li>Jeux vidéos</li>
                    <li>Astronomie, astrophysique</li>
                </ul>
            </SkillBar>
        </section>
    )
}

export default SkillSection

import { useContext, useEffect, useRef, useState } from 'react'
import PageTitle from '../components/molecules/pageTitle'
import './Portfolio.scss'
import { DataContext } from '../assets/datas'
import SelectedProjectContent from '../components/organisms/selectedProjectContent'
import ProjectHeader from '../components/molecules/projectHeader'
import PortfolioCategoriesNav from '../components/molecules/portfolioNav'
import Modal from '../components/molecules/Modal'
import ArticleCarousel from '../components/organisms/projectPicturesCarousel'
import { useCarousel } from '../assets/carouselIndexHandler'
import { useMediaQuery } from 'react-responsive'

const PortfolioPage = () => {
    const { projects, loading, error } = useContext(DataContext)
    const [categories, setCategories] = useState(new Set())
    const [currentCategory, setCurrentCategory] = useState('')
    const [currentColor, setCurrentColor] = useState('')
    const [projectsFiltered, setProjectsFiltered] = useState([])
    const selectionContainerRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)
    const colorsClass = ['pink', 'blue', 'orange', 'white']
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

    const {
        currentIndex,
        setCurrentIndex,
        selectedProject,
        setSelectedProject,
    } = useCarousel()

    const stopVideo = () => {
        const videoElements = document.querySelectorAll('video')
        videoElements.forEach((video) => {
            video.pause()
        })
    }

    const openNewProject = (project, index) => {
        setSelectedProject(project)
        setCurrentColor(`${colorsClass[index % colorsClass.length]}`)
        stopVideo()
    }

    // Défini les catégories en fonction de la liste des projets
    useEffect(() => {
        if (projects.length > 0) {
            const categoriesSet = new Set(
                projects.map((project) => project.category)
            )
            setCategories(categoriesSet)
        }
    }, [projects])

    // Défini la première catégorie au chargement
    useEffect(() => {
        if (categories.size > 0) {
            const firstCategory = Array.from(categories)[0]
            projectsByCategory(firstCategory)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])

    // Scrolling des projets
    useEffect(() => {
        if (selectedProject && selectionContainerRef.current) {
            const selectedIndex = projectsFiltered.findIndex(
                (project) => project.id === selectedProject.id
            )
            setCurrentIndex(0)

            // Assurez-vous que cette valeur est correcte par rapport au SCSS
            const projectWidth = 185 + 40
            const projectWidthMobile = 116 + 40
            const projectWidthToUse = isMobile
                ? projectWidthMobile
                : projectWidth

            const newScrollPosition = selectedIndex * projectWidthToUse
            setScrollPosition(newScrollPosition)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, projectsFiltered])

    // Défini le premier projet par défaut lors du clic sur une catégorie
    const projectsByCategory = (category) => {
        setCurrentCategory(category)
        const projectsByCat = projects.filter(
            (project) => project.category === category
        )
        setProjectsFiltered(projectsByCat)
        setSelectedProject(projectsByCat[0] || null)
        setCurrentColor(`${colorsClass[0]}`)

        setScrollPosition(0)
    }

    const openModal = () => {
        setIsModalOpen(true)
        stopVideo()
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            {selectedProject && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <ArticleCarousel
                        medias={selectedProject.medias}
                        stopVideo={stopVideo}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                    />
                </Modal>
            )}
            <main className="portfolioPage page-anim">
                <PageTitle
                    title="Portfolio"
                    subtitle="Des projets pour toutes mes compétences !"
                />
                <PortfolioCategoriesNav
                    categories={categories}
                    projectsByCategory={projectsByCategory}
                    currentCategory={currentCategory}
                />
                {loading ? (
                    error ? (
                        <p>Erreur de Chargement.</p>
                    ) : (
                        <p>Chargement en cours...</p>
                    )
                ) : (
                    <div className="project__container">
                        <div className="project__selection-wrapper">
                            <div
                                className="project__selection"
                                ref={selectionContainerRef}
                                style={{
                                    transform: `translateX(-${scrollPosition}px)`,
                                }}
                            >
                                {projectsFiltered.map((project, index) => (
                                    <ProjectHeader
                                        key={project.id}
                                        project={project}
                                        selectedProject={selectedProject}
                                        onClick={() =>
                                            openNewProject(project, index)
                                        }
                                        colorsClass={`${colorsClass[index % colorsClass.length]}BgCat${isMobile ? '--mobile' : ''}`}
                                    />
                                ))}
                            </div>
                        </div>

                        {selectedProject && (
                            <SelectedProjectContent
                                key={selectedProject.id}
                                selectedProject={selectedProject}
                                stopVideo={() => stopVideo()}
                                openModal={openModal}
                            />
                        )}
                        <div
                            className={`cat-tail-bottom cat-tail-bottom-${currentColor}`}
                        ></div>
                    </div>
                )}

                <PortfolioCategoriesNav
                    categories={categories}
                    projectsByCategory={projectsByCategory}
                    currentCategory={currentCategory}
                />
            </main>
        </>
    )
}

export default PortfolioPage

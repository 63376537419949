import { useEffect, useRef } from 'react'

const MediaItem = ({ item, className }) => {
    const videoRef = useRef(null)
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.pause()
            videoRef.current.currentTime = 0 // Optionnel : remettre la vidéo au début
        }
    }, [item.videoUrl])
    return item.imageUrl ? (
        <img
            className={className}
            src={item.imageUrl}
            alt={item.imageAltText}
            loading="lazy"
        />
    ) : (
        <div className="video__wrapper">
            <video
                className={className}
                ref={videoRef}
                controls
                controlsList="nodownload nofullscreen"
            >
                <source src={item.videoUrl} type="video/mp4" />
            </video>
        </div>
    )
}

export default MediaItem

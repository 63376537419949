import { useEffect, useState } from 'react'
import './catch.scss'

const CatchSection = () => {
    const expertIn = [
        'UX Design.',
        'Web Design.',
        'Direction Artistique.',
        'Illustration.',
    ]

    const [currentSkillIndex, setCurrentSkillIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSkillIndex(
                (prevIndex) => (prevIndex + 1) % expertIn.length
            )
        }, 4000)

        return () => clearInterval(interval)
    }, [expertIn.length])

    return (
        <>
            <div className="catch">
                <h1 className="title">Salut !</h1>
                <div id="home__subtitle" className="subtitle">
                    <p>Je m’appelle Mélanie ! </p>
                    <p id="expert__catch">
                        {''}Passionnée de{' '}
                        {expertIn.map((exp, index) => (
                            <span
                                key={index}
                                className={`expert ${index === currentSkillIndex ? 'slideIn' : ''}`}
                            >
                                {exp}
                            </span>
                        ))}
                    </p>
                </div>
            </div>
            <a
                className="link-external"
                href="https://www.malt.fr/profile/melaniechabrol"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div>
                    <p>
                        Je suis présente sur{' '}
                        <span className="malt">malt.fr</span>
                    </p>
                </div>
            </a>
        </>
    )
}

export default CatchSection

import React from 'react'
import './Modal.scss'

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null

    return (
        <div className="modal-wrapper">
            <div className="modal-overlay">
                <div className="modal-content">
                    <button className="modal-close" onClick={onClose}>
                        &times;
                    </button>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal

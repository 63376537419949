import './catTail.scss'

const CatTail = ({ color, selected }) => {
    // Check the Variables SCSS file for color values
    const trueColor = () => {
        switch (color) {
            case 'pinkBgCat':
            case 'pinkBgCat--mobile':
                return '#f1a89a'
            case 'blueBgCat':
            case 'blueBgCat--mobile':
                return '#d9cce6'
            case 'orangeBgCat':
            case 'orangeBgCat--mobile':
                return '#f7b641'
            case 'whiteBgCat':
            case 'whiteBgCat--mobile':
                return '#fffef0'
            default:
                return '#fffef0'
        }
    }

    return (
        <svg
            className={`cat-tail ${selected}`}
            xmlns="http://www.w3.org/2000/svg"
            width="1093"
            height="85"
            viewBox="0 0 1093 85"
            fill="none"
        >
            <path
                d="M1058.54 37.228V17C1058.54 7.61116 1066.16 0 1075.54 0H1075.9C1085.29 0 1092.9 7.61114 1092.9 17V67.4013C1092.9 76.7901 1085.29 84.4013 1075.9 84.4013H15.0866C6.75451 84.4013 0 77.6467 0 69.3146C0 60.9825 6.75459 54.228 15.0867 54.228H1041.54C1050.93 54.228 1058.54 46.6169 1058.54 37.228Z"
                fill={trueColor()}
            />
        </svg>
    )
}

export default CatTail

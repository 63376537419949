import MySelf from '../components/molecules/myself'
import './home.scss'
import CatchSection from '../components/molecules/catch'
import SkillSection from '../components/organisms/skillSection'
import ShowMore from '../components/molecules/showMore'

const Home = () => {
    return (
        <main className="home page-anim">
            <CatchSection />
            <div className="presentation">
                <MySelf />
                <SkillSection />
            </div>
            <ShowMore />
        </main>
    )
}

export default Home

const PortfolioCategoriesNav = ({
    categories,
    projectsByCategory,
    currentCategory,
}) => {
    return (
        <div className="portfolio__nav">
            {Array.from(categories).map((category, index) => (
                <div key={`div-${index}`} className="portfolio__nav__item">
                    <p
                        key={index}
                        onClick={() => projectsByCategory(category)}
                        className={
                            category === currentCategory
                                ? 'cat selected'
                                : 'cat'
                        }
                    >
                        {category}
                    </p>
                    <svg
                        key={`svg-${index}`}
                        className={
                            category === currentCategory
                                ? 'selection-point portfolio__nav__item--selected'
                                : 'portfolio__nav__item--hidden'
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                    >
                        <circle cx="7" cy="7" r="7" fill="black" />
                    </svg>
                </div>
            ))}
        </div>
    )
}

export default PortfolioCategoriesNav

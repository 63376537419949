import { createContext, useState, useContext, useEffect } from 'react'

const CarouselContext = createContext()

export const useCarousel = () => useContext(CarouselContext)

export const CarouselProvider = ({ children }) => {
    const [currentIndex, setCurrentIndex] = useState()
    const [selectedProject, setSelectedProject] = useState(null)

    useEffect(() => {
        if (selectedProject !== null) {
            setCurrentIndex(0) // Reset index when selectedProject changes
        }
    }, [selectedProject])

    const contextValue = {
        currentIndex,
        setCurrentIndex,
        selectedProject,
        setSelectedProject,
    }

    return (
        <CarouselContext.Provider value={contextValue}>
            {children}
        </CarouselContext.Provider>
    )
}

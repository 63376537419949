import { Link } from 'react-router-dom'

const ShowMore = () => {
    return (
        <div className="show-more">
            <h2>Envie d'en voir plus ?</h2>
            <Link to="/portfolio" className="linkBtn">
                Voir le portfolio
            </Link>
        </div>
    )
}

export default ShowMore

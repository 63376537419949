const PageTitle = ({ title, subtitle }) => {
    return (
        <div className="catch">
            <h1 className="title">{title}</h1>
            <div>
                <p className="subtitle">{subtitle}</p>
            </div>
        </div>
    )
}

export default PageTitle

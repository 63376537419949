import './Footer.scss'

const Footer = () => {
    return (
        <footer>
            <p>Chabrol Mélanie</p>
            <p>chabrol.melanie.pro@gmail.com</p>
            <a
                className="footer-insta-btn"
                href="https://www.instagram.com/melanie_chabrol?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <path
                        d="M15.0067 7.29443C10.7478 7.29443 7.31252 10.676 7.31252 14.8684C7.31252 19.0608 10.7478 22.4424 15.0067 22.4424C19.2656 22.4424 22.7009 19.0608 22.7009 14.8684C22.7009 10.676 19.2656 7.29443 15.0067 7.29443ZM15.0067 19.7925C12.2545 19.7925 10.0045 17.5842 10.0045 14.8684C10.0045 12.1526 12.2478 9.94434 15.0067 9.94434C17.7656 9.94434 20.0089 12.1526 20.0089 14.8684C20.0089 17.5842 17.7589 19.7925 15.0067 19.7925ZM24.8103 6.98462C24.8103 7.9668 24.0067 8.75122 23.0156 8.75122C22.0179 8.75122 21.221 7.96021 21.221 6.98462C21.221 6.00903 22.0246 5.21802 23.0156 5.21802C24.0067 5.21802 24.8103 6.00903 24.8103 6.98462ZM29.9063 8.77759C29.7924 6.41113 29.2433 4.31494 27.4822 2.58789C25.7277 0.86084 23.5982 0.320312 21.1942 0.20166C18.7165 0.0632324 11.2902 0.0632324 8.81252 0.20166C6.4152 0.313721 4.28573 0.854248 2.52457 2.5813C0.76341 4.30835 0.221 6.40454 0.100464 8.771C-0.0401611 11.21 -0.0401611 18.5203 0.100464 20.9592C0.214303 23.3257 0.76341 25.4219 2.52457 27.1489C4.28573 28.876 6.4085 29.4165 8.81252 29.5352C11.2902 29.6736 18.7165 29.6736 21.1942 29.5352C23.5982 29.4231 25.7277 28.8826 27.4822 27.1489C29.2366 25.4219 29.7857 23.3257 29.9063 20.9592C30.0469 18.5203 30.0469 11.2166 29.9063 8.77759ZM26.7054 23.5762C26.1831 24.8682 25.1719 25.8635 23.8527 26.3843C21.8773 27.1555 17.1897 26.9775 15.0067 26.9775C12.8237 26.9775 8.12948 27.1489 6.16073 26.3843C4.84823 25.8701 3.83707 24.8748 3.30805 23.5762C2.52457 21.6316 2.70537 17.0173 2.70537 14.8684C2.70537 12.7195 2.53127 8.09863 3.30805 6.16064C3.83037 4.86865 4.84154 3.87329 6.16073 3.35254C8.13618 2.5813 12.8237 2.75928 15.0067 2.75928C17.1897 2.75928 21.8839 2.58789 23.8527 3.35254C25.1652 3.8667 26.1764 4.86206 26.7054 6.16064C27.4889 8.10522 27.3081 12.7195 27.3081 14.8684C27.3081 17.0173 27.4889 21.6382 26.7054 23.5762Z"
                        fill="black"
                    />
                </svg>
            </a>
        </footer>
    )
}

export default Footer

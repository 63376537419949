import MediaItem from '../molecules/carouselItem'
import { useEffect, useState } from 'react'
import './projectPicturesCarousel.scss'
import { useCarousel } from '../../assets/carouselIndexHandler'

const ArticleCarousel = ({ medias, stopVideo, index, openModal }) => {
    const { currentIndex, setCurrentIndex } = useCarousel()
    const mediaItems = [
        ...(medias.videos ? medias.videos : []),
        ...(medias.images ? medias.images : []),
    ]
    const [openAnimation, setOpenAnimation] = useState('')

    if (index !== undefined) {
        setCurrentIndex(index)
    }

    const totalItems = mediaItems.length

    // Fonction pour obtenir l'index de l'élément précédent
    const getPreviousIndex = () => (currentIndex - 1 + totalItems) % totalItems
    const getSecondPreviousIndex = () =>
        (currentIndex - 2 + totalItems) % totalItems
    const getNextIndex = () => (currentIndex + 1) % totalItems
    const getSecondNextIndex = () => (currentIndex + 2) % totalItems

    const handleNext = () => {
        stopVideo()
        setCurrentIndex(getNextIndex())
    }
    const handlePrev = () => {
        stopVideo()
        setCurrentIndex(getPreviousIndex())
    }

    function setVolumeForAllVideos(volumeLevel) {
        const videos = document.querySelectorAll('video')
        videos.forEach((video) => {
            video.volume = volumeLevel // Set volume to a value between 0.0 and 1.0
        })
    }

    useEffect(() => {
        setOpenAnimation('project-carousel_animation--hide')
        setVolumeForAllVideos(0.5)
        setTimeout(() => {
            setOpenAnimation('project-carousel_animation--show')
        }, 200)
    }, [medias])

    // Créer une liste d'indices visibles
    const visibleIndices = [
        currentIndex,
        getPreviousIndex(),
        getSecondPreviousIndex(),
        getNextIndex(),
        getSecondNextIndex(),
    ]

    // Filtrer les éléments pour ne conserver que ceux dont l'index est visible
    const visibleItems = mediaItems.filter((_, index) =>
        visibleIndices.includes(index)
    )

    // On ne chargera jamais plus de 5 photos/vidéos à la fois
    const itemsToDisplay = mediaItems.length <= 5 ? mediaItems : visibleItems
    return (
        <div className={`project-carousel_animation ${openAnimation}`}>
            <div className="article__image-container">
                {totalItems > 1 && (
                    <>
                        <button
                            className="carousel-button left"
                            onClick={handlePrev}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="47"
                                height="52"
                                viewBox="0 0 47 52"
                                fill="none"
                            >
                                <path
                                    d="M2.99999 20.8039C-1.00001 23.1133 -0.999998 28.8868 3 31.1962L37.5 51.1147C41.5 53.4241 46.5 50.5374 46.5 45.9186L46.5 6.08141C46.5 1.4626 41.5 -1.42414 37.5 0.885262L2.99999 20.8039Z"
                                    fill="black"
                                />
                            </svg>
                        </button>
                        <button
                            className="carousel-button right"
                            onClick={handleNext}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="47"
                                height="52"
                                viewBox="0 0 47 52"
                                fill="none"
                            >
                                <path
                                    d="M44 20.8039C48 23.1133 48 28.8868 44 31.1962L9.49999 51.1147C5.49999 53.4241 0.499997 50.5374 0.499998 45.9186L0.499999 6.08141C0.5 1.4626 5.5 -1.42414 9.5 0.885262L44 20.8039Z"
                                    fill="black"
                                />
                            </svg>
                        </button>
                    </>
                )}
                {/* Mapping des Images/Videos */}
                {itemsToDisplay.map((item, index) => {
                    const itemIndex = mediaItems.indexOf(item)
                    return (
                        <MediaItem
                            key={itemIndex}
                            item={item}
                            openModal={openModal}
                            className={`article__image ${(() => {
                                switch (itemIndex) {
                                    case currentIndex:
                                        return 'article__image-center'
                                    case getPreviousIndex():
                                        return 'article__image-previous'
                                    case getNextIndex():
                                        return 'article__image-next'
                                    case getSecondPreviousIndex():
                                        return 'article__image-previous-preload'
                                    case getSecondNextIndex():
                                        return 'article__image-next-preload'
                                    default:
                                        return 'article__image-unselected'
                                }
                            })()}`}
                        />
                    )
                })}
                <div className="carousel-pagination__wrapper">
                    {mediaItems.map((item, index) => (
                        <span
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={`carousel-pagination__item ${mediaItems.length > 8 ? 'carousel-pagination__item--small' : ''} ${currentIndex === index ? 'carousel-pagination__item--selected' : ''} `}
                        >
                            {' '}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ArticleCarousel

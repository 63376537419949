import React, { createContext, useState, useEffect } from 'react'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        fetch('https://api.clementdegardenzi.fr/api/melprojects')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((datas) => {
                setProjects(datas)
                setLoading(false)
            })
            .catch((error) => {
                setError(error)
                setLoading(false)
            })
    }, [])

    return (
        <DataContext.Provider value={{ projects, loading, error }}>
            {children}
        </DataContext.Provider>
    )
}

const SmolCat = () => {
    return (
        <svg
            className="cv__languages__cat"
            xmlns="http://www.w3.org/2000/svg"
            width="211"
            height="196"
            viewBox="0 0 211 196"
            fill="none"
        >
            <path
                d="M44.1376 43.4409C48.6376 39.9409 60.6376 35.0409 72.6376 43.4409C73.6376 38.9409 76.8376 30.2409 81.6376 31.4409C86.4376 32.6409 89.3042 45.9409 90.1376 52.4409C94.6376 58.2742 100.938 66.8511 90.1376 77.6511C116.293 61.2428 150.617 59.9449 175.5 76.5539C179 65.0539 155.368 22.6511 198.868 2.65112C198.868 2.65112 204.268 2.85112 201.868 7.65112C198.868 13.6511 174.5 5.94467 191 90.9447C207.232 111.677 214.193 145.145 201.868 192.941H191C170.29 131.453 131 115.151 100 115.151C60.5 115.151 31.5 143.651 18.5 192.941H3V181.941C3 115.651 33.3624 102.151 39 97.6511C16.8624 91.6511 21.4709 58.2742 26.1376 52.4409C25.6376 37.9409 32.6376 29.4409 36.6376 31.4409C39.8376 33.0409 42.9709 40.1075 44.1376 43.4409Z"
                fill="black"
            />
            <path
                d="M175.5 76.5539C179 65.0539 155.368 22.6511 198.868 2.65112C198.868 2.65112 204.268 2.85112 201.868 7.65112C198.868 13.6511 174.5 5.94467 191 90.9447M44.1376 43.4409C48.6376 39.9409 60.6376 35.0409 72.6376 43.4409C73.6376 38.9409 76.8376 30.2409 81.6376 31.4409C86.4376 32.6409 89.3042 45.9409 90.1376 52.4409C94.6376 58.2742 100.938 66.8511 90.1376 77.6511C144 43.8614 232.5 74.1511 201.868 192.941H191C170.29 131.453 131 115.151 100 115.151C60.5 115.151 31.5 143.651 18.5 192.941H3V181.941C3 115.651 33.3624 102.151 39 97.6511C16.8624 91.6511 21.4709 58.2742 26.1376 52.4409C25.6376 37.9409 32.6376 29.4409 36.6376 31.4409C39.8376 33.0409 42.9709 40.1075 44.1376 43.4409Z"
                stroke="#1E1E1E"
                stroke-width="5"
                stroke-linecap="round"
            />
            <ellipse
                cx="46.1377"
                cy="66.9409"
                rx="8"
                ry="12.5"
                fill="#DFEDE0"
            />
            <ellipse
                cx="70.1377"
                cy="66.9409"
                rx="8"
                ry="12.5"
                fill="#DFEDE0"
            />
            <path
                d="M22 63.9216L8 59.9216"
                stroke="#1E1E1E"
                stroke-linecap="square"
            />
            <path
                d="M22.2422 69.9297L8.24219 72.9297"
                stroke="#1E1E1E"
                stroke-linecap="square"
            />
            <path
                d="M93.9385 57.8462L105.84 49.4581"
                stroke="#1E1E1E"
                stroke-linecap="square"
            />
            <path
                d="M95.689 63.5989L109.896 61.8202"
                stroke="#1E1E1E"
                stroke-linecap="square"
            />
        </svg>
    )
}

export default SmolCat
